import axios from 'axios';
import { store } from '../store.js';
import { loadWhoami } from '../components/Users/actions/usersActions';
import { handleTranslationsSettingsAction, loadConfigurations, removeSettingsAction } from './settingsActions';
import { initializeTranslations } from './translationsActions';
import { setPromptDialogIsDirty } from '../components/Generic/actions/promptActions';
import { fetchTicketbyTokenAction, postTicketAction } from '../components/Tickets/actions/ticketsActions.js';
import { fetchActivitybyTokenAction } from '../components/Activities/actions/activitiesActions.js';
import { push } from 'connected-react-router';
import { handlePersistentAlert, handleSplashScreenAction } from '../components/Generic/actions/genericActions.js';
import { setWebsocketToken } from '../components/Chat/actions/websocketsActions.js';
import { RESET_TOKEN } from '../components/Login/actions/types';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { getTranslate } from 'react-localize-redux';

export const initializeAction = () => async (dispatch) => {
	const state = store.getState();

	const responseConfiguration = await dispatch(loadConfigurations());

	const responseInitializeTranslations = await dispatch(initializeTranslations(responseConfiguration));

	await dispatch(handleTranslationsSettingsAction(responseInitializeTranslations));

	try {
		await dispatch(loadWhoami(true));

    if (responseConfiguration.site.maintenance) {
      const translate = getTranslate(state.localize);

      await dispatch(handlePersistentAlert({
        open: true,
        message: translate('persistent_alert_message_maintenance'),
        severity: 'info',
        icon: (<EngineeringIcon />)
      }));
    }

		if (!state.login.login || state.router.location.search.length !== 0) {
			await dispatch(handleRedirect(false));

			await dispatch(
				removeSettingsAction([
					'tickets',
					'activities',
					'conditions_id',
					'conditions',
					'practitioners_id',
					'starts_at',
					'ends_at',
					'token',
					'messages_id',
					'recovery_token',
					'recovery_medium',
					'recovery_value',
				])
			);
		}
	} catch (error) {
		return dispatch(handleSplashScreenAction(false));
	}

	return dispatch(handleSplashScreenAction(false));
};

export const handleRedirect =
	(force = true) =>
	async (dispatch) => {
		const state = store.getState();

		let onlytoken = false;
		if (state.settings.params !== null) {
			if (Object.keys(state.settings.params).length === 1 && typeof state.settings.params.token !== 'undefined') {
				onlytoken = true;
				await dispatch(removeSettingsAction(['token']));
			}
		}

		if (state.users.whoami.status === '10') {
			return await Promise.all([
				dispatch(push('/myprofile')),
				...(window.self === window.top
					? [
							dispatch(
								setPromptDialogIsDirty({
									is_dirty: true,
									path_allowed: ['/myprofile'],
									prompt_dialog_title: 'prompt_user_incomplete_title',
									prompt_dialog_description: 'prompt_user_incomplete_description',
									allow_resume: false,
								})
							),
					  ]
					: []),
			]);
		} else if (
			state.settings.params !== null &&
			state.settings.params.events_id &&
			state.settings.params.modal_catalogue &&
			state.settings.feature.dashboard
		) {
			return await dispatch(push('/dashboard'));
		} else if (state.router.location?.state?.referrer) {
			return await dispatch(push(state.router.location?.state?.referrer + state.router.location?.search));
		} else if (state.settings.params !== null && typeof state.settings.params.tickets !== 'undefined') {
			await dispatch(fetchTicketbyTokenAction(state.settings.params.tickets, true, true));
			return await dispatch(
				removeSettingsAction([
					'tickets',
					'activities',
					'conditions_id',
					'conditions',
					'practitioners_id',
					'starts_at',
					'ends_at',
					'token',
					'messages_id',
					'recovery_token',
					'recovery_medium',
					'recovery_value',
				])
			);
		} else if (state.settings.params !== null && typeof state.settings.params.activities !== 'undefined') {
			await dispatch(fetchActivitybyTokenAction(state.settings.params.activities));
			return await dispatch(
				removeSettingsAction([
					'tickets',
					'activities',
					'conditions_id',
					'conditions',
					'practitioners_id',
					'starts_at',
					'ends_at',
					'token',
					'messages_id',
					'recovery_token',
					'recovery_medium',
					'recovery_value',
				])
			);
		} else if (state.settings.params !== null && typeof state.settings.params.conditions_id !== 'undefined') {
			await dispatch(postTicketAction(null, state.settings.params));
			return await dispatch(
				removeSettingsAction([
					'tickets',
					'activities',
					'conditions_id',
					'conditions',
					'practitioners_id',
					'starts_at',
					'ends_at',
					'token',
					'messages_id',
					'recovery_token',
					'recovery_medium',
					'recovery_value',
				])
			);
		} else if (state.settings.params !== null && typeof state.settings.params.messages_id !== 'undefined') {
			// TODO: Change to flow like clicking on notification from bell
			await dispatch(push('/dashboard'));
			return await dispatch(
				removeSettingsAction([
					'tickets',
					'activities',
					'conditions_id',
					'conditions',
					'practitioners_id',
					'starts_at',
					'ends_at',
					'token',
					'messages_id',
					'recovery_token',
					'recovery_medium',
					'recovery_value',
				])
			);
		} else if (
			state.settings.params !== null &&
			typeof state.settings.params.recovery_medium !== 'undefined' &&
			typeof state.settings.params.recovery_value !== 'undefined' &&
			typeof state.settings.params.recovery_token !== 'undefined'
		) {
			let recoveryMediums = state.settings.auth.supported_recovery_password_mediums
				? state.settings.auth.supported_recovery_password_mediums
						.replace(/,\s*$/, '')
						.split(',')
						.map((item) => item.trim())
						.filter((item) => item.length > 0)
				: [];
			if (recoveryMediums.includes(state.settings.params.recovery_medium)) {
				await dispatch({
					type: RESET_TOKEN,
					payload: {
						reset_medium: state.settings.params.recovery_medium,
						reset_value: state.settings.params.recovery_value,
						reset_token: state.settings.params.recovery_token,
					},
				});
			}
			await dispatch(push('/password'));
		} else {
			if (
				state.router.location.pathname !== '/' &&
				state.router.location.pathname.split('/')[1] !== 'login' &&
				state.router.location.pathname !== '/backoffice' &&
				!force
			) {
				return;
			} else if (
				state.login.self_registration &&
				state.users.whoami.scopes.includes('tickets.new_case') &&
				(state.settings.params == null || onlytoken)
			) {
				return await dispatch(push('/conditions'));
			} else if (state.settings.feature.dashboard && (state.settings.params == null || onlytoken) && state.users.whoami.redirect_url === null) {
				return await dispatch(push('/dashboard'));
			} else if (state.settings.feature.dashboard && (state.settings.params == null || onlytoken) && state.users.whoami.redirect_url !== null) {
				return await dispatch(push(state.users.whoami.redirect_url));
			} else if (state.users.whoami.scopes.includes('lists.all_cases')) {
				return await dispatch(push('/allcases'));
			} else if (state.users.whoami.scopes.includes('practitioner')) {
				return await dispatch(push('/mycases'));
			} else if (state.users.whoami.scopes.includes('customer')) {
				if (state.settings.feature.dashboard && (state.settings.params == null || onlytoken) && state.users.whoami.redirect_url === null) {
					return await dispatch(push('/dashboard'));
				} else if (
					state.settings.feature.dashboard &&
					(state.settings.params == null || onlytoken) &&
					state.users.whoami.redirect_url !== null
				) {
					return await dispatch(push(state.users.whoami.redirect_url));
				} else if (
					state.users.whoami.scopes.includes('tickets.new_case') &&
					((state.settings.params !== null && typeof state.settings.params.conditions !== 'undefined') ||
						state.users.whoami.ticket_count === 0)
				) {
					return await dispatch(push('/conditions'));
				} else {
					return await dispatch(push('/mycases'));
				}
			} else {
				return await dispatch(push('/mycases'));
			}
		}
	};
