import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { SnackbarProvider } from 'notistack';
import Routes from './Routes';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { addSettingsAction } from './actions/settingsActions';
import { setPromptDialogIsDirty } from './components/Generic/actions/promptActions';
import { withRouter } from 'react-router-dom';
import ReactRouterPause from '@allpro/react-router-pause';
import PromptDialog from './components/Generic/PromptDialog';
import SplashScreen from './components/Layout/SplashScreen';
import CssBaseline from '@mui/material/CssBaseline';
import { logoutAction } from './components/Login/actions/loginActions';
import { initializeAction } from './actions/genericActions';
import { closePlayerAction, handlePersistentAlert } from './components/Generic/actions/genericActions';
import { getTranslate } from 'react-localize-redux';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// Our theme provider
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './assets/Theme';
import IdleTimer from './IdleTimer';
import BlockUI from './components/Layout/BlockUI';
import Progress from './components/Generic/Progress';
import Modal from './components/Layout/Modal';
import { MessageToast, NotificationsModal } from './components/Generic';
import ImageDisplayCards from './components/Generic/ImageDisplayCards';
import Player from './components/Layout/Player';
import SideBar from './components/Layout/SideBar';
import ModalCatalogue from './components/Layout/ModalCatalogue';
import SocketProvider from './components/Chat/SocketProvider';
import Footer from './components/Layout/Footer';
import { Box } from '@mui/material';
import { Engineering } from '@mui/icons-material';

class Main extends Component {
	constructor(props) {
		super(props);

		if (props.location.search.length !== 0) {
			let search = props.location.search.substring(1);

			search = search.split('&');

			let params = {};

			search.forEach((param) => {
				param = param.split('=');
				Object.assign(params, { [param[0]]: param[1] });
			});
			if (
				params.conditions ||
				params.language ||
				params.tickets ||
				params.activities ||
				params.conditions_id ||
				params.practitioners_id ||
				params.starts_at ||
				params.ends_at ||
				params.start || 
        params.token ||
        params.messages_id ||
        params.recovery_token ||
        params.recovery_medium ||
        params.recovery_value ||
        params.jwt ||
        params.user_token ||
        params.clients_id ||
        params.events_id ||
				params.modal_catalogue ||
        params.login_method
			) {
				let aux = { ...params };
				Object.keys(aux).forEach((key) => {
					aux[key] = decodeURI(aux[key]);
				});

				props.addSettingsAction(aux);
			} else {
				props.replace(props.location.pathname);
			}
		}

		this.state = { showDialog: false, showDialogLogoutPrompt: false };
		this.navigation = null;

		this.cache = createCache({
			key: 'my-prefix-key',
			nonce: 'N2M0MDhkN2EtMmRkYi00MTExLWFhM2YtNDhkNTc4NGJhMjA3',
			prepend: true,
		});
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		this.props.initializeAction(
			true,
			!this.props.login.login || this.props.location.pathname === '/' || this.props.location.pathname === '/login'
		);

		if (
			typeof this.props.settings.feature !== 'undefined' &&
			this.props.settings.feature.matomo &&
			typeof this.props.settings.matomo !== 'undefined' &&
			this.props.settings.matomo.container
		) {
			this.setupMatomoConnection();
		}
	}

	componentDidUpdate(prevProps) {
		if (!this.props.lightbox.open) {
			Object.assign(document.body.style, { overflow: 'auto', margin: '0px', paddingRight: '0px' });
		}

		if (this.state.showDialog && !this.props.login.login) {
			this.closeDialog();
		}

		if (
			this.props.login.login &&
			typeof this.props.settings.feature !== 'undefined' &&
			this.props.settings.feature.matomo &&
			typeof this.props.settings.matomo !== 'undefined' &&
			this.props.settings.matomo.container
		) {
			var _mtm = (window._mtm = window._mtm || []);
			_mtm.push({ role: this.props.user.role });
		}

    if (this.props.login.login && this.props.settings.site.maintenance) {
      this.props.handlePersistentAlert({
        open: true,
        message: this.props.translate('persistent_alert_message_maintenance'),
        severity: 'info',
        icon: (<Engineering />)
      })
    }
	}

	// INFO set up matomo connection with the matomo host from confg and container from settings
	setupMatomoConnection = () => {
		if (
			this.props.login.login &&
			typeof this.props.settings.feature !== 'undefined' &&
			this.props.settings.feature.matomo &&
			typeof this.props.settings.matomo !== 'undefined' &&
			this.props.settings.matomo.container
		) {
			var _paq = (window._paq = window._paq || []);
			_paq.push(['trackPageView']);
			_paq.push(['enableLinkTracking']);
			let config = this.props.config; // cache the this
			let container = this.props.settings.matomo.container; // cache the this
			(function () {
				var u = config.matomohost;
				_paq.push(['setTrackerUrl', u + 'matomo.php']);
				_paq.push(['setSiteId', '2']);
				var d = document,
					g = d.createElement('script'),
					s = d.getElementsByTagName('script')[0];
				g.type = 'text/javascript';
				g.async = true;
				g.src = u + 'matomo.js';
				s.parentNode.insertBefore(g, s);
			})();

			var _mtm = (window._mtm = window._mtm || []);
			_mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
			var d = document,
				g = d.createElement('script'),
				s = d.getElementsByTagName('script')[0];
			g.type = 'text/javascript';
			g.async = true;
			g.src = config.matomohost + 'js/container_' + container + '.js';
			s.parentNode.insertBefore(g, s);
		}
	};

	handleNavigationAttempt = (navigation, location, action) => {
		this.navigation = navigation;

		// Implement path_not_allowed behavior:
		// if (this.props.path_not_allowed !== null && this.props.path_not_allowed.some(v => location.pathname.includes(v)))

		if (this.props.is_dirty) {
			if (action === 'POP') {
				this.setState({ showDialog: true });
				return null;
			} else if (this.props.path_allowed !== null && !this.props.path_allowed.some((v) => location.pathname.includes(v))) {
				this.setState({ showDialog: true });
				return null;
			} else {
				return true;
			}
		} else if (this.props.login.login) {
			const regex = new RegExp('login');

			if (action === 'POP' && regex.test(location.pathname)) {
				this.setState({ showDialogLogoutPrompt: true });
				return null;
			} else {
				return true;
			}
		}

		// Return null to 'pause' and save the route so can 'resume'
	};

	closeDialog = () => {
		this.setState({ showDialog: false });
	};

	closeLogoutDialog = () => {
		this.setState({ showDialogLogoutPrompt: false });
	};

	handleStay = () => {
		this.closeDialog();
		this.navigation.cancel();
	};

	handleStayLogout = () => {
		this.closeLogoutDialog();
		this.navigation.cancel();
	};

	handleLeave = () => {
		this.closeDialog();

		this.navigation.resume();
		this.props.setPromptDialogIsDirty({ is_dirty: false });

		if (this.props.leave_action !== null && typeof this.props.leave_action == 'function') {
			this.props.leave_action();
		}
	};

	handleLeaveLogout = () => {
		this.closeLogoutDialog();

		this.navigation.resume();
		this.props.logoutAction(true, false);
	};

	render() {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
				<CacheProvider value={this.cache}>
					<SocketProvider>
						<ThemeProvider theme={theme(this.props.settings, this.props.client)}>
							<CssBaseline />
							<IdleTimer>
								<ReactRouterPause handler={this.handleNavigationAttempt} when={this.props.is_dirty || this.props.login.login} />
								<PromptDialog
									open={this.state.showDialog}
									cancel={this.handleStay}
									resume={this.handleLeave}
									back_label={this.props.back_label}
									next_label={this.props.next_label}
								/>
								<PromptDialog
									open={this.state.showDialogLogoutPrompt}
									cancel={this.handleStayLogout}
									resume={this.handleLeaveLogout}
									back_label={'generic_button_stay'}
									next_label={'generic_button_logout'}
									prompt_dialog_title={'prompt_logout_dialog_title'}
									prompt_dialog_description={'prompt_logout_dialog_description'}
								/>
								<Box sx={{ minHeight: 'none !important', display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
									<SnackbarProvider maxSnack={10} className={'snackbar-variant-info'}>
										{this.props.splash_screen ? (
											<SplashScreen />
										) : (
											<Routes settings={this.props.settings} config={this.props.config} />
										)}
									</SnackbarProvider>
									<SideBar />
								</Box>
								<BlockUI blocking={true} />
								<MessageToast />
								<Modal />
								<NotificationsModal />
								<Progress />
								<ModalCatalogue />
								{this.props.lightbox.open || this.props.player.open ? (
									<React.Fragment>
										<div style={{ display: 'none' }}>
											{this.props.lightbox.open && this.props.lightbox.image ? (
												<ImageDisplayCards
													id={'question_' + this.props.question_index}
													initialFiles={this.props.lightbox.image}
													limitFiles={1}
													lightboxOnly={true}
												/>
											) : null}
											{this.props.player.open && this.props.player.url ? (
												<Player
													open={this.props.player.open}
													url={this.props.player.url}
													onClose={() => this.props.closePlayerAction()}
												/>
											) : null}
										</div>
									</React.Fragment>
								) : null}
								{!this.props.splash_screen && this.props.login.login && <Footer />}
							</IdleTimer>
						</ThemeProvider>
					</SocketProvider>
				</CacheProvider>
			</Box>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.users.whoami,
	publisher: state.publisher,
	is_dirty: state.prompt.is_dirty,
	path_allowed: state.prompt.path_allowed,
	path_not_allowed: state.prompt.path_not_allowed,
	leave_action: state.prompt.leave_action,
	back_label: state.prompt.back_label,
	next_label: state.prompt.next_label,
	lightbox: state.generic.lightbox,
	splash_screen: state.generic.splash_screen,
	login: state.login,
	settings: state.settings,
	config: state.config,
	client: state.login.client,
	player: state.generic.player,
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, {
	setPromptDialogIsDirty,
	addSettingsAction,
	replace,
	logoutAction,
	initializeAction,
	closePlayerAction,
  handlePersistentAlert
})(withLocalize(withRouter(Main)));
